.switchContainer {
	display: flex;
	margin: 1px 5px 1px 5px;
}

.toggleSwitchCheckbox {
	height: 0;
	width: 0;
	visibility: hidden;
}

.toggleSwitchCheckboxDisabled {
	height: 0;
	width: 0;
	visibility: hidden;
}

.toggleSwitchLabel {
	display: flex;
	align-items: center;
	width: 30px;
	height: 10px;
	position: relative;
}

.toggleSwitchButton {
	position: absolute;
	width: 20px;
	height: 7px;
	background-color: #c3c5c6;
	transition: transform 0.4s ease;
	border-radius: 34px;
}

.toggleSwitchButton:before {
	position: absolute;
	content: '';
	height: 11px;
	width: 11px;
	top: -2px;
	transition: 0.2s ease;
	background-color: #8b9095;
	border-radius: 20px;
}

.toggleSwitchCheckbox:checked + .toggleSwitchButton {
	background-color: #f6a07b;
}

.toggleSwitchCheckbox:checked + .toggleSwitchButton:before {
	transform: translateX(100%);
	background-color: #f26122;
}

.toggleSwitchCheckboxDisabled:checked + .toggleSwitchButton {
	background-color: #c3c5c6;
}

.toggleSwitchCheckboxDisabled:checked + .toggleSwitchButton:before {
	transform: translateX(100%);
	background-color: #8b9095;
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.pointer {
	cursor: pointer;
}

.isPointerDisabled {
	cursor: not-allowed;
}