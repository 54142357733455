.select :global(.slds-button) {
	font-size: 0.7vw !important;
	line-height: 0.7vw !important;
	border-radius: 16px;
	color: white !important;
	padding: 0.5vh 0.4vw !important;
	height: 15px;
	background-color: #8b9095;
}

.select :global(.slds-button > *) {
	color: white !important;
}

.select a {
	color: white;
}

.active :global(.slds-button) {
	background-color: #14bb02;
	padding: 0.5vh 0.8vw !important;
}

.active a,
.active a:hover {
	color: #14bb02;
}

.approved :global(.slds-button),
.draft :global(.slds-button),
.underDevelopment :global(.slds-button),
.forActivation :global(.slds-button),
.forReview :global(.slds-button) {
	background-color: #cccf19;
	padding: 0.5vh 0.8vw !important;
}

.select .approved a,
.select .draft a,
.select .underDevelopment a,
.select .forActivation a,
.select .forReview a {
	color: #cccf19;
}

div.forReview {
	color: var(--orange);
}

div.approved {
	color: #14bb02;
}

div.draft {
	color: #f26122;
}

div.rejected {
	color: red;
}

.rejected :global(.slds-button),
.suspended :global(.slds-button),
.deactivated :global(.slds-button) {
	background-color: red;
	padding: 0.5vh 0.8vw !important;
}

.select .rejected a,
.select .suspended a,
.select .deactivated a {
	color: red;
}

.terminated :global(.slds-button) {
	background-color: #8b9095;
	padding: 0.5vh 0.8vw !important;
}

.select .terminated a {
	color: #8b9095;
}